// TdhStatsComponent.js

import React, { useState, useEffect } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import './TdhStatsComponent.css'; // Import the component-specific CSS

const TdhStatsComponent = () => {
    const [tableData, setTableData] = useState([]);
    const [lastUpdated, setLastUpdated] = useState('');
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        fetch('https://stats6529backend-f135373a86cd.herokuapp.com/api/tdh_stats')
            .then(response => response.json())
            .then(data => {
                console.log("Raw API data:", data);
                
                // Sort the data by date
                const sortedData = (data.data || []).sort((a, b) => new Date(b.date) - new Date(a.date));
                setTableData(sortedData);

                // Prepare data for the graph
                const dailyData = {};
                sortedData.forEach(item => {
                    const date = new Date(item.date).toISOString().split('T')[0];
                    const pptdh = parseFloat(item.pptdh);
                    if (!dailyData[date] || pptdh < dailyData[date].pptdh) {
                        dailyData[date] = { date, pptdh };
                    }
                });
                const graphData = Object.values(dailyData).sort((a, b) => new Date(a.date) - new Date(b.date));
                setGraphData(graphData);

                // Set the last updated date and time using the lastUpdated from the API
                if (data.lastUpdated) {
                    const apiUpdateTime = new Date(data.lastUpdated);
                    
                    const utcTime = apiUpdateTime.toUTCString();
                    const localTime = apiUpdateTime.toLocaleString('en-GB', {
                        timeZone: 'Europe/London',
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    });

                    setLastUpdated(`UK Time: ${localTime}, UTC: ${utcTime.split(' ').slice(1, 5).join(' ')}`);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    // Group rows by date
    const groupRowsByDate = (data) => {
        const groups = {};
        data.forEach(row => {
            const date = new Date(row.date).toISOString().split('T')[0];
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(row);
        });
        return Object.entries(groups);
    };

    return (
        <div className="tdh-stats-container">
            <h1 className="main-header">TDH Stats</h1>
            {lastUpdated && <p className="last-updated">Last Updated: {lastUpdated}</p>}

            <h2 className="sub-header">PPTDH Over Time</h2>
            <div className="chart-container">
                <div className="inner-chart">
                    <ResponsiveContainer>
                        <LineChart
                            data={graphData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="pptdh" stroke="red" strokeWidth={4} activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>

            <h2 className="sub-header">Lowest PPTDH Each Day</h2>
            <div className="table-container">
                <div className="inner-chart">
                    <table className="styled-table centered-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Token No</th>
                                <th>Name</th>
                                <th>Meme Name</th>
                                <th>Supply</th>
                                <th>SZN</th>
                                <th>Ask Price</th>
                                <th>TDH Rate</th>
                                <th>PPTDH</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupRowsByDate(tableData).map(([date, rows], groupIndex) => (
                                rows.map((row, rowIndex) => (
                                    <tr key={`${date}-${rowIndex}`} className={groupIndex % 2 === 0 ? 'even-row' : 'odd-row'}>
                                        <td>{new Date(row.date).toISOString().split('T')[0]}</td>
                                        <td>{row.token_no}</td>
                                        <td>{row.name}</td>
                                        <td>{row.meme_name}</td>
                                        <td>{row.supply}</td>
                                        <td>{row.szn}</td>
                                        <td>{parseFloat(row.ask_price).toFixed(4)}</td>
                                        <td>{parseFloat(row.tdh_rate).toFixed(4)}</td>
                                        <td>{parseFloat(row.pptdh).toFixed(4)}</td>
                                    </tr>
                                ))
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TdhStatsComponent;
