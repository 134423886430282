// FooterComponent.js

import React from 'react';
import { Link } from 'react-router-dom';
import './FooterComponent.css'; // Import the component-specific CSS

const FooterComponent = () => {
  return (
    <footer className="footer-container">
      <div className="footer-inner">
        {/* Creator Information */}
        <p className="creator-info">Created by Vantekai</p>

        {/* Social Links */}
        <div className="social-links">
          <a
            href="https://x.com/Vantekai_"
            target="_blank"
            rel="noopener noreferrer"
          >
            X: Vantekai
          </a>
          <a
            href="https://seize.io/Vantekai"
            target="_blank"
            rel="noopener noreferrer"
          >
            Seize: Vantekai
          </a>
          {/* Email Address */}
          <a href="mailto:admin@6529stats.xyz" className="email-link">
            admin@6529stats.xyz
          </a>
        </div>

        {/* Contact Button */}
        <div className="footer-contact-button">
          <Link to="/contact" className="green-button">
            Contact
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
