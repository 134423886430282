// MyPortfolioComponent.js

import React, { useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import { ArrowUpDown, Info } from 'lucide-react';
import { CSVLink } from 'react-csv';
import './MyPortfolioComponent.css';
import { NavLink } from 'react-router-dom';

const API_BASE_URL = 'https://stats6529backend-f135373a86cd.herokuapp.com';

const MyPortfolioComponent = () => {
  // State variables
  const [profile, setProfile] = useState('');
  const [portfolioData, setPortfolioData] = useState([]);
  const [collectionValuation, setCollectionValuation] = useState({
    bid: 0,
    ask: 0,
    lastSale: 0,
    withDepth: 0,
  });
  const [sznValuations, setSznValuations] = useState([]);
  const [sznCostToCompleteFloor, setSznCostToCompleteFloor] = useState([]);
  const [completeCollectionCostToCompleteFloor, setCompleteCollectionCostToCompleteFloor] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedSZN, setSelectedSZN] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [ownershipFilter, setOwnershipFilter] = useState('all');

  /**
   * Handles form submission to fetch portfolio data.
   */
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${API_BASE_URL}/api/portfolio_all/${profile}`);
      setPortfolioData(response.data.data);
      setCollectionValuation({
        bid: response.data.collectionValuationBid,
        ask: response.data.collectionValuationAsk,
        lastSale: response.data.collectionValuationLastSale,
        withDepth: response.data.collectionValuationWithDepth,
      });

      // Extract SZN Valuations and Costs
      const sznData = response.data.sznValuations || [];
      const sznCostData = response.data.sznCostToCompleteFloor || [];

      setSznValuations(sznData);
      setSznCostToCompleteFloor(sznCostData);
      setCompleteCollectionCostToCompleteFloor(response.data.CompleteCollectionCostToCompleteFloor || 0);
      setLastUpdated(new Date(response.data.lastUpdated));
    } catch (err) {
      setError('Error fetching portfolio data. Please try again.');
      console.error('Error details:', err.response ? err.response.data : err.message);
    }
    setLoading(false);
  }, [profile]);

  /**
   * Formats numbers to a fixed number of decimal places with thousand separators.
   */
  const formatNumber = (num) => {
    return num.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 });
  };

  /**
   * Formats the date to display both UK and UTC times.
   */
  const formatDate = (date) => {
    const ukTime = date.toLocaleString('en-GB', {
      timeZone: 'Europe/London',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const utcTime = date.toLocaleString('en-GB', {
      timeZone: 'UTC',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return `UK Time: ${ukTime}, UTC: ${utcTime}`;
  };

  /**
   * Handles sorting of table columns.
   */
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  /**
   * Memoized sorted data based on sort configuration.
   */
  const sortedData = useMemo(() => {
    let sortableItems = [...portfolioData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        const isNumericField = [
          'ID',
          'HighestBid',
          'FloorPrice',
          'LastSalePrice',
          'PPTDH',
          'SeizedCount',
          'BidValuation',
          'ValuationWithDepth',
          'AskValuation',
          'TypeSeason',
        ].includes(sortConfig.key);

        if (isNumericField) {
          const aNum = parseFloat(aValue) || 0;
          const bNum = parseFloat(bValue) || 0;
          return sortConfig.direction === 'ascending' ? aNum - bNum : bNum - aNum;
        } else {
          if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableItems;
  }, [portfolioData, sortConfig]);

  /**
   * Memoized filtered data based on selected filters and search term.
   */
  const filteredData = useMemo(() => {
    return sortedData.filter(item =>
      (selectedSZN === 'All' || item.TypeSeason === selectedSZN) &&
      (searchTerm === '' || item.Name.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (ownershipFilter === 'all' ||
        (ownershipFilter === 'owned' && item.SeizedCount > 0) ||
        (ownershipFilter === 'notowned' && item.SeizedCount === 0))
    );
  }, [sortedData, selectedSZN, searchTerm, ownershipFilter]);

  /**
   * Extracts unique SZNs from the portfolio data for filter options.
   */
  const uniqueSZNs = useMemo(() => {
    const szns = new Set(portfolioData.map(item => item.TypeSeason));
    return ['All', ...Array.from(szns).sort((a, b) => a - b)];
  }, [portfolioData]);

  /**
   * Provides search suggestions based on the current search term.
   */
  const suggestions = useMemo(() => {
    if (searchTerm.length < 2) return [];
    const uniqueNames = new Set(portfolioData.map(item => item.Name));
    return Array.from(uniqueNames).filter(name =>
      name.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(0, 5);
  }, [portfolioData, searchTerm]);

  return (
    <div className="my-portfolio-container">
      {/* Header and Form */}
      <h1 className="my-portfolio-page-header">My 6529 Memes Portfolio</h1>
      <div className="my-portfolio-form-container">
        <form onSubmit={handleSubmit} className="my-portfolio-form">
          <input
            type="text"
            value={profile}
            onChange={(e) => setProfile(e.target.value)}
            placeholder="Enter your Seize profile"
            required
          />
          <button type="submit">
            Fetch Seize Portfolio
          </button>
        </form>
        <p className="my-portfolio-description">
          Enter your Seize profile or any wallet address within your consolidation to receive your current Bid &amp; Ask price valuations for your 6529 Memes portfolio
        </p>
      </div>

      {/* Loading and Error Messages */}
      {loading && <p className="my-portfolio-loading-message">Loading...</p>}
      {error && <p className="my-portfolio-error-message">{error}</p>}

      {/* Portfolio Valuation Table */}
      {portfolioData.length > 0 && (
        <>
          <h2 className="my-portfolio-sub-header">My Portfolio</h2>

          <div className="my-portfolio-complete-portfolio-data">
            <table className="my-portfolio-styled-table">
              <thead>
                <tr>
                  <th>Season</th>
                  <th>Valuation (Bid Prices)</th>
                  <th>Valuation (Bids Price With Depth)</th>
                  <th>Valuation (Ask Prices)</th>
                  <th>Valuation (Last Sale)</th>
                  <th>Cost to Complete (Ask Prices)</th>
                </tr>
              </thead>
              <tbody>
                {sznValuations.map((szn) => {
                  const costData = sznCostToCompleteFloor.find(item => item.szn === szn.szn);
                  return (
                    <tr key={szn.szn}>
                      <td>SZN {szn.szn}</td>
                      <td>{formatNumber(szn.bid)}</td>
                      <td>{formatNumber(szn.bidWithDepth || 0)}</td>
                      <td>{formatNumber(szn.ask)}</td>
                      <td>{formatNumber(szn.lastSale)}</td>
                      <td>{formatNumber(costData ? costData.costToComplete : 0)}</td>
                    </tr>
                  );
                })}
                <tr className="my-portfolio-total-row">
                  <td>Complete Collection</td>
                  <td>{formatNumber(collectionValuation.bid)}</td>
                  <td>{formatNumber(collectionValuation.withDepth)}</td>
                  <td>{formatNumber(collectionValuation.ask)}</td>
                  <td>{formatNumber(collectionValuation.lastSale)}</td>
                  <td>{formatNumber(completeCollectionCostToCompleteFloor)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Download Button for Portfolio Table */}
          <div className="my-portfolio-download-button">
            <CSVLink
              data={portfolioData}
              headers={[
                { label: 'ID', key: 'ID' },
                { label: 'Name', key: 'Name' },
                // Add other headers as needed
              ]}
              filename={`my_portfolio_${profile}.csv`}
              className="my-portfolio-green-button"
            >
              Download CSV
            </CSVLink>
          </div>

          {/* Explanation Box */}
          <div className="my-portfolio-explanation-box">
            <p><strong>These valuations are calculated using:</strong></p>
            <ul>
              <li>Highest bid price per token</li>
              <li>Lowest ask price (floor price) per token</li>
              <li>Valuation considering market depth</li>
            </ul>
            <p><strong>Note:</strong> The new valuation method accounts for market depth by summing up available bids for the quantity of tokens you own.</p>
            {lastUpdated && (
              <>
                <p><strong>Prices Last Updated at:</strong> {formatDate(lastUpdated)}</p>
                <p><strong>** Price data updated approx every 2 minutes **</strong></p>
              </>
            )}
          </div>

          <br /><br />

          {/* Portfolio Details Table */}
          <h2 className="my-portfolio-sub-header">My Portfolio Details</h2>

          {/* Link to MyPortfolioBidsComponent */}
    <div className="my-portfolio-link-container">
      <NavLink
        to={`/my-portfolio-bids?profile=${profile}`}
        target="_blank"
        rel="noopener noreferrer"
        className="my-portfolio-green-button"
      >
        View Bids Used in Valuation
      </NavLink>
    </div>

          {/* Filters and Search */}
          <div className="my-portfolio-filter-container">
            <div>
              <label htmlFor="szn-filter">SZN: </label>
              <select
                id="szn-filter"
                value={selectedSZN}
                onChange={(e) => setSelectedSZN(e.target.value)}
              >
                {uniqueSZNs.map((szn) => (
                  <option key={szn} value={szn}>{szn}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="ownership-filter">Ownership: </label>
              <select
                id="ownership-filter"
                value={ownershipFilter}
                onChange={(e) => setOwnershipFilter(e.target.value)}
              >
                <option value="all">All Tokens</option>
                <option value="owned">Owned Tokens</option>
                <option value="notowned">Tokens NOT Owned</option>
              </select>
            </div>
            <div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setShowSuggestions(true);
                }}
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                placeholder="Search cards..."
              />
              {showSuggestions && suggestions.length > 0 && (
                <ul className="my-portfolio-suggestions-list">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onMouseDown={() => {
                        setSearchTerm(suggestion);
                        setShowSuggestions(false);
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Portfolio Details Table */}
          <table className="my-portfolio-details-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('ID')}>
                  Token No <ArrowUpDown className="my-portfolio-inline-icon" />
                </th>
                <th>
                  Card Name
                </th>
                <th onClick={() => handleSort('TypeSeason')}>
                  SZN <ArrowUpDown className="my-portfolio-inline-icon" />
                </th>
                <th onClick={() => handleSort('HighestBid')}>
                  Highest Bid <ArrowUpDown className="my-portfolio-inline-icon" />
                </th>
                <th onClick={() => handleSort('FloorPrice')}>
                  Floor Price <ArrowUpDown className="my-portfolio-inline-icon" />
                </th>
                <th onClick={() => handleSort('LastSalePrice')}>
                  Last Sale <ArrowUpDown className="my-portfolio-inline-icon" />
                </th>
                <th onClick={() => handleSort('PPTDH')}>
                  PPTDH <ArrowUpDown className="my-portfolio-inline-icon" />
                </th>
                <th onClick={() => handleSort('SeizedCount')}>
                  Seized Count <ArrowUpDown className="my-portfolio-inline-icon" />
                </th>
                <th onClick={() => handleSort('BidValuation')}>
                  Bid Valuation <ArrowUpDown className="my-portfolio-inline-icon" />
                </th>
                <th onClick={() => handleSort('ValuationWithDepth')}>
                  Valuation (Bids Price With Depth) <ArrowUpDown className="my-portfolio-inline-icon" />
                </th>
                <th onClick={() => handleSort('AskValuation')}>
                  Ask Valuation <ArrowUpDown className="my-portfolio-inline-icon" />
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => (
                <tr key={item.ID} className={ownershipFilter === 'all' && item.SeizedCount === 0 ? 'my-portfolio-highlight' : ''}>
                  <td>{item.ID}</td>
                  <td>
                    <NavLink
                      to={`/token/${item.ID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="my-portfolio-name-link"
                    >
                      {item.Name} <Info className="my-portfolio-inline-icon" />
                    </NavLink>
                  </td>
                  <td>{item.TypeSeason}</td>
                  <td>{formatNumber(item.HighestBid)}</td>
                  <td>{formatNumber(item.FloorPrice)}</td>
                  <td>{item.LastSalePrice !== null ? formatNumber(item.LastSalePrice) : 'N/A'}</td>
                  <td>{item.PPTDH !== null ? formatNumber(item.PPTDH) : 'N/A'}</td>
                  <td>{item.SeizedCount}</td>
                  <td>{formatNumber(item.BidValuation)}</td>
                  <td>{formatNumber(item.ValuationWithDepth)}</td>
                  <td>{formatNumber(item.AskValuation)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Download Button for Portfolio Details Table */}
          <div className="my-portfolio-download-button">
            <CSVLink
              data={filteredData}
              headers={[
                { label: 'ID', key: 'ID' },
                { label: 'Name', key: 'Name' },
                // Add other headers as needed
              ]}
              filename={`my_portfolio_details_${profile}.csv`}
              className="my-portfolio-green-button"
            >
              Download CSV
            </CSVLink>
          </div>
        </>
      )}
    </div>
  );
};

export default MyPortfolioComponent;
