import React from 'react';

const ThankYou = () => {
  return (
    <div className="thank-you-container">
      <h1>Thank You!</h1>
      <p>Your message has been sent successfully. We'll get back to you soon.</p>
    </div>
  );
};

export default ThankYou;
