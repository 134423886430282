// SalesBotAllTimeComponent.js

import React, { useState, useEffect, useMemo } from 'react';
import { fetchSalesBotAllTimeData } from './apiService';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
import { ArrowUpDown, Info } from 'lucide-react';
import './SalesBotAllTimeComponent.css';
import { NavLink } from 'react-router-dom'; // Import NavLink

// Import images (ensure these files are inside src/assets/)
import etherscanIcon from './assets/etherscan.png';
import openseaIcon from './assets/openseaIcon.png';
import seizeIcon from './assets/seizeIcon.png';

// Utility function to capitalize first letter
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function SalesBotAllTimeComponent() {
  // State variables
  const [salesData, setSalesData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'createdat', direction: 'desc' });
  const [selectedSaleType, setSelectedSaleType] = useState('All');
  const [selectedMemeName, setSelectedMemeName] = useState('All');
  const [selectedSZN, setSelectedSZN] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;
  const [totalRecords, setTotalRecords] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // List of meme names
  const memeNames = [
    'Freedom to Transact',
    'Brain',
    'Open Metaverse / OM',
    'WAGMI',
    'Survive',
    'GM',
    'Seize the Memes of Production',
    "Don't Let the Institutions Steal Your JPGs",
    'Use a Hardware Wallet',
    'Freestyle',
    'Not Your Keys, Not Your Coins',
    'Digital Rights Charter',
    'BUIDL',
    'TAP',
    'Summer.jpg',
    // Add other meme names here
  ];

  // Fetch data from backend
  const fetchData = async () => {
    try {
      const response = await fetchSalesBotAllTimeData({
        page: currentPage + 1,
        pageSize: itemsPerPage,
        saleType: selectedSaleType !== 'All' ? selectedSaleType : undefined,
        memeName: selectedMemeName !== 'All' ? selectedMemeName : undefined,
        szn: selectedSZN !== 'All' ? selectedSZN : undefined,
        searchTerm: searchTerm !== '' ? searchTerm : undefined,
        startDate: startDate !== '' ? startDate : undefined,
        endDate: endDate !== '' ? endDate : undefined,
        sortKey: sortConfig.key,
        sortDirection: sortConfig.direction,
      });
      setSalesData(response.data);
      setTotalRecords(response.totalCount);
    } catch (error) {
      console.error('Error fetching sales bot data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    selectedSaleType,
    selectedMemeName,
    selectedSZN,
    searchTerm,
    sortConfig,
  ]);

  // Implement sorting
  const sortTable = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setCurrentPage(0); // Reset to first page when sorting changes
  };

  // Handle page change
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  // Prepare data for CSV download
  const csvData = useMemo(() => {
    const headers = [
      { label: "Date", key: "createdat" },
      { label: "Name", key: "name" },
      { label: "Sale Type", key: "orderside" },
      { label: "Amount (ETH)", key: "decimal" },
      { label: "Amount (USD)", key: "usd" },
      { label: "Meme Name", key: "memename" },
      { label: "SZN", key: "typeseason" },
      { label: "Seller", key: "from_address" },
      { label: "Buyer", key: "to_address" },
      { label: "Tx Hash", key: "txhash" },
    ];

    const data = salesData.map(item => ({
      ...item,
      orderside: capitalizeFirstLetter(item.orderside),
      decimal: parseFloat(item.decimal).toFixed(5),
      usd: parseFloat(item.usd).toFixed(2),
    }));

    return {
      headers,
      data,
    };
  }, [salesData]);

  // Handle Search button click
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(0);
    fetchData();
  };

  // Handle Clear Search button click
  const handleClearSearch = () => {
    setStartDate('');
    setEndDate('');
    setSelectedMemeName('All');
    setSelectedSaleType('All');
    setSelectedSZN('All');
    setSearchTerm('');
    setCurrentPage(0);
    fetchData();
  };

  return (
    <div className="sales-bot-all-time">
      {/* Updated Title */}
      <h2 className="title">6529 Memes Sales History</h2>

      {/* Form */}
      <form className="filters-form" onSubmit={handleSearch}>
        {/* First row of filters */}
        <div className="filters-row">
          <div className="filter-item">
            <label htmlFor="sale-type-filter">Sale Type</label>
            <select
              id="sale-type-filter"
              value={selectedSaleType}
              onChange={(e) => {
                setSelectedSaleType(e.target.value);
              }}
            >
              <option value="All">All</option>
              <option value="ask">Ask</option>
              <option value="bid">Bid</option>
            </select>
          </div>
          <div className="filter-item">
            <label htmlFor="meme-name-filter">Meme Name</label>
            <select
              id="meme-name-filter"
              value={selectedMemeName}
              onChange={(e) => {
                setSelectedMemeName(e.target.value);
              }}
            >
              <option value="All">All</option>
              {memeNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
              ))}
            </select>
          </div>
          <div className="filter-item">
            <label htmlFor="szn-filter">SZN</label>
            <select
              id="szn-filter"
              value={selectedSZN}
              onChange={(e) => {
                setSelectedSZN(e.target.value);
              }}
            >
              <option value="All">All</option>
              {[...Array(10).keys()].slice(1).map((szn) => (
                <option key={szn} value={szn}>{szn}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Second row of filters */}
        <div className="filters-row">
          <div className="filter-item">
            <label htmlFor="start-date-filter">Start Date</label>
            <input
              type="date"
              id="start-date-filter"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </div>
          <div className="filter-item">
            <label htmlFor="end-date-filter">End Date</label>
            <input
              type="date"
              id="end-date-filter"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div>
          <div className="filter-item">
            <label htmlFor="search-input">Search Name</label>
            <input
              type="text"
              id="search-input"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setShowSuggestions(true);
                setCurrentPage(0);
              }}
              onFocus={() => setShowSuggestions(true)}
              onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
              placeholder="Enter name to search"
            />
            {showSuggestions && searchTerm.length >= 2 && (
              <ul className="suggestions-list">
                {salesData
                  .map(item => item.name)
                  .filter((name, index, self) => name.toLowerCase().includes(searchTerm.toLowerCase()) && self.indexOf(name) === index)
                  .slice(0, 5)
                  .map((suggestion, index) => (
                    <li
                      key={index}
                      onMouseDown={() => {
                        setSearchTerm(suggestion);
                        setShowSuggestions(false);
                        setCurrentPage(0);
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>

        {/* Buttons and record count */}
        <div className="action-buttons-row">
          <div className="buttons-group">
            <button type="submit">Search</button>
            <button type="button" onClick={handleClearSearch}>Clear Search</button>
          </div>
          <div className="records-and-download">
            <span><strong>Number of Records:</strong> {totalRecords}</span>
            <CSVLink
              data={csvData.data}
              headers={csvData.headers}
              filename={`sales_bot_all_time.csv`}
              className="btn btn-primary"
            >
              Download CSV
            </CSVLink>
          </div>
        </div>
      </form>

      {/* Table */}
      <table>
        <thead>
          <tr>
            {/* Sortable Columns */}
            <th onClick={() => sortTable('createdat')}>
              <div className="header-content">
                Date
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'createdat' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            {/* Non-Sortable Columns */}
            <th>Image</th>
            <th>Name</th>
            {/* Sortable Columns */}
            <th onClick={() => sortTable('orderside')}>
              <div className="header-content">
                Sale Type
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'orderside' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('decimal')}>
              <div className="header-content">
                Amount (ETH)
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'decimal' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('usd')}>
              <div className="header-content">
                Amount (USD)
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'usd' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('memename')}>
              <div className="header-content">
                Meme Name
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'memename' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('typeseason')}>
              <div className="header-content">
                SZN
                <ArrowUpDown
                  className={`sort-icon ${sortConfig.key === 'typeseason' ? (sortConfig.direction === 'asc' ? 'sorted-asc' : 'sorted-desc') : ''}`}
                />
              </div>
            </th>
            {/* Non-Sortable Columns */}
            <th>Seller</th>
            <th>Buyer</th>
            <th>Tx</th>
            <th>Opensea</th>
            <th>Seize</th>
          </tr>
        </thead>
        <tbody>
          {salesData.map((item, index) => (
            <tr key={index}>
              <td>{formatDate(item.createdat)}</td>
              <td>
                <img
                  src={item.image}
                  alt={item.name}
                  className="item-image"
                />
              </td>
              <td>
                <NavLink
                  to={`/token/${item.tokenid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="name-link"
                >
                  {item.name} <Info className="inline-icon" />
                </NavLink>
              </td>
              <td>{capitalizeFirstLetter(item.orderside)}</td>
              <td>{parseFloat(item.decimal).toFixed(5)}</td>
              <td>${parseFloat(item.usd).toFixed(2)}</td>
              <td>{item.memename}</td>
              <td>{item.typeseason}</td>
              <td>
                <a
                  href={`https://etherscan.io/address/${item.from_address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.from_address.slice(0, 6)}...{item.from_address.slice(-4)}
                </a>
              </td>
              <td>
                <a
                  href={`https://etherscan.io/address/${item.to_address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.to_address.slice(0, 6)}...{item.to_address.slice(-4)}
                </a>
              </td>
              <td>
                <a
                  href={`https://etherscan.io/tx/${item.txhash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={etherscanIcon} alt="Etherscan" className="icon-image" />
                </a>
              </td>
              <td>
                <a
                  href={`https://opensea.io/assets/ethereum/${item.contract}/${item.tokenid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={openseaIcon} alt="Opensea" className="icon-image" />
                </a>
              </td>
              <td>
                <a
                  href={`https://seize.io/the-memes/${item.tokenid}?focus=live`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={seizeIcon} alt="Seize" className="icon-image" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          breakLabel={'...'}
          pageCount={Math.ceil(totalRecords / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
    </div>
  );
}

export default SalesBotAllTimeComponent;
