// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PricesComponent from './PricesComponent';
import TdhStatsComponent from './TdhStatsComponent';
import TdhTableComponent from './TdhTableComponent';
import PricesHistoryComponent from './PricesHistoryComponent';
import MyPortfolioComponent from './MyPortfolioComponent';
import SalesSummaryComponent from './SalesSummaryComponent';
import SalesBotAllTimeComponent from './SalesBotAllTimeComponent';
import SalesAnalyticsComponent from './SalesAnalyticsComponent';
import NotificationsComponent from './NotificationsComponent';
import FooterComponent from './FooterComponent';
import TokenOwnersComponent from './TokenOwnersComponent';
import HodlerAggregatedComponent from './HodlerAggregatedComponent';
import MyPortfolioBidsComponent from './MyPortfolioBidsComponent';

// Import the ContactForm and ThankYou components
import ContactForm from './ContactForm';
import ThankYou from './pages/ThankYou';

// Import the TokenComponent
import TokenComponent from './TokenComponent';

// Import the InsightsComponent
import InsightsComponent from './InsightsComponent';

// Import the HodlerSupplyComponent
import HodlerSupplyComponent from './HodlerSupplyComponent';

// Import the Navbar component
import Navbar from './Navbar';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content-wrapper">
          <Navbar /> {/* Use the Navbar component */}
          <main>
            <Routes>
              <Route path="/" element={<PricesComponent />} />
              <Route path="/insights" element={<InsightsComponent />} />
              <Route path="/tdh-stats" element={<TdhStatsComponent />} />
              <Route path="/tdh-table" element={<TdhTableComponent />} />
              <Route path="/prices-history" element={<PricesHistoryComponent />} />
              <Route path="/sales-summary" element={<SalesSummaryComponent />} />
              <Route path="/sales-analytics" element={<SalesAnalyticsComponent />} />
              <Route path="/sales-all-time" element={<SalesBotAllTimeComponent />} />
              <Route path="/portfolio" element={<MyPortfolioComponent />} />
              <Route path="/notifications" element={<NotificationsComponent />} />
              {/* Add routes for ContactForm and ThankYou components */}
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/thank-you" element={<ThankYou />} />
              {/* Add the Route for TokenComponent */}
              <Route path="/token/:tokenid" element={<TokenComponent />} />
              <Route path="/token/:tokenid/owners" element={<TokenOwnersComponent />} />
              <Route path="/hodlers/aggregated" element={<HodlerAggregatedComponent />} />
              <Route path="/hodlers/supply" element={<HodlerSupplyComponent />} />
              <Route path="/my-portfolio-bids" element={<MyPortfolioBidsComponent />} />
            </Routes>
          </main>
        </div>
        <FooterComponent />
      </div>
    </Router>
  );
}

export default App;
