// HodlerAggregatedComponent.js

import React, { useEffect, useState } from 'react';
import { fetchOwnersAggregatedStatsLatest } from './apiService';
import './HodlerAggregatedComponent.css';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronRight } from 'lucide-react'; // For collapse icons

const HodlerAggregatedComponent = () => {
  const [data, setData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState('');
  const [expandedSeasons, setExpandedSeasons] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchOwnersAggregatedStatsLatest();
        setData(response.data);
        // Format fetch_timestamp
        const timestamp = new Date(response.data.fetch_timestamp);
        const ukTime = timestamp.toLocaleString('en-GB', {
          timeZone: 'Europe/London',
          day: '2-digit',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        });
        setLastUpdated(ukTime);
      } catch (error) {
        console.error('Error fetching aggregated stats:', error);
      }
    };
    getData();
  }, []);

  const toggleSeason = (season) => {
    setExpandedSeasons((prev) =>
      prev.includes(season) ? prev.filter((s) => s !== season) : [...prev, season]
    );
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  // Prepare overall metrics
  const overallMetrics = [
    { metric: 'Hodl Any Card', count: data.holders_any_tokenid },
    { metric: 'Full Set Hodler', count: data.holders_all_tokenid },
    { metric: 'Hodl Min 1 Card Per SZN', count: data.holders_any_token_each_season },
  ];

  // Prepare season metrics
  const seasonMetrics = [];
  for (let i = 1; i <= 9; i++) {
    seasonMetrics.push({
      season: `SZN${i}`,
      anyCard: data[`holders_any_season${i}`],
      fullHodler: data[`holders_all_season${i}`],
    });
  }

  return (
    <div className="hodler-aggregated-container">
      <h1 className="main-header">Hodler Aggregated Data</h1>
      <div className="last-updated">
        Updated daily at 1.00 am UTC. Last Update: {lastUpdated}
      </div>
      <p className="description">
        A Hodler is a consolidated profile on{' '}
        <a href="https://seize.io/network?page=1" target="_blank" rel="noopener noreferrer">
          Seize.io
        </a>{' '}
        that can include up to 3 Ethereum addresses owned by the Hodler. This gives a far more accurate reflection of how many unique individuals hold cards.
      </p>

      {/* Overall Metrics Table */}
      <div className="metrics-card">
        <h2 className="card-title">Overall Collection Metrics</h2>
        <table className="centered-table">
          <thead>
            <tr>
              <th>Metric</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {overallMetrics.map((item, index) => (
              <tr key={index}>
                <td>{item.metric}</td>
                <td>{item.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Season Metrics */}
      <div className="season-metrics">
        <h2 className="card-title">Season Specific Metrics</h2>
        {seasonMetrics.map((season, index) => (
          <div key={index} className="season-card">
            <div className="season-header" onClick={() => toggleSeason(season.season)}>
              <span>{season.season}</span>
              {expandedSeasons.includes(season.season) ? (
                <ChevronDown className="chevron-icon" />
              ) : (
                <ChevronRight className="chevron-icon" />
              )}
            </div>
            {expandedSeasons.includes(season.season) && (
              <div className="season-content">
                <table className="centered-table">
                  <tbody>
                    <tr>
                      <td>Hodl Any {season.season} Card</td>
                      <td>{season.anyCard}</td>
                    </tr>
                    <tr>
                      <td>Full {season.season} Hodler</td>
                      <td>{season.fullHodler}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}
      </div>

      <p className="contact-text">
        If there are any additional hodler stats you would like to see here then please contact
      </p>
      <div className="contact-button-container">
        <Link to="/contact" className="green-button">
          Contact
        </Link>
      </div>
    </div>
  );
};

export default HodlerAggregatedComponent;
