// InsightsComponent.js

import React, { useEffect, useState } from 'react';
import { fetchDailyMetrics } from './apiService';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './InsightsComponent.css';

const InsightsComponent = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getDailyMetrics = async () => {
      try {
        const response = await fetchDailyMetrics();
        setData(response.data);
      } catch (error) {
        console.error('Error fetching daily metrics:', error);
      }
    };

    getDailyMetrics();
  }, []);

  if (!data) {
    return <div className="insights-container">Loading...</div>;
  }

  const { interval_metrics, single_metrics } = data;

  // Prepare data for the first table (Sales)
  const timeIntervals = ['24h', '7d', '30d', 'ytd'];
  const intervalMetricsRows = Object.entries(interval_metrics).map(([metricName, timeData]) => {
    const row = { metricName };
    timeIntervals.forEach(interval => {
      row[interval] = timeData[interval] || 'N/A';
    });
    return row;
  });

  // Define the order of single metrics
  const singleMetricOrder = [
    'Holders Count',
    'Full Set Holders',
    'Hold Min One Card per SZN',
    'Lowest PPTDH'
  ];
  const singleMetricsRows = singleMetricOrder.map(metricName => ({
    metricName,
    metricValue: single_metrics[metricName] || 'N/A',
  }));

  // Helper function to split name and amount for "Highest Sale" and "Highest Revenue Card"
  const splitNameAndAmount = (value) => {
    const match = value.match(/^(.+)\s+\((.+)\)$/);
    if (match) {
      const idMatch = match[1].match(/\d+/);
      const id = idMatch ? idMatch[0] : null;
      const name = id ? match[1].replace(id, '').trim() : match[1].trim();
      return (
        <>
          {id && <div>ID: {id}</div>}
          <div>{name}</div>
          <div>({match[2]})</div>
        </>
      );
    } else {
      return value;
    }
  };

  // Helper function to render metric value with colored arrows
  const renderMetricValueWithArrow = (value) => {
    const arrowUp = '↑';
    const arrowDown = '↓';
    if (value.includes(arrowUp)) {
      const parts = value.split(arrowUp);
      return (
        <>
          {parts[0]}
          <span className="arrow-up">{arrowUp}{parts[1]}</span>
        </>
      );
    } else if (value.includes(arrowDown)) {
      const parts = value.split(arrowDown);
      return (
        <>
          {parts[0]}
          <span className="arrow-down">{arrowDown}{parts[1]}</span>
        </>
      );
    } else {
      return value;
    }
  };

  return (
    <div className="insights-container">
      <h1 className="main-header">Insights</h1>
      {/* Removed the "Key Insights and Metrics" subtitle */}

      {/* First Table: Sales */}
      <h2 className="section-header">Sales</h2>
      <table className="metrics-table">
        <thead>
          <tr>
            <th>Metric</th>
            {timeIntervals.map(interval => (
              <th key={interval}>{interval}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {intervalMetricsRows.map((row, index) => (
            <tr key={index} className={index % 2 !== 0 ? 'even-row' : ''}>
              <td className="metric-name-cell">{row.metricName}</td>
              {timeIntervals.map(interval => (
                <td key={interval}>
                  {["Highest Sale", "Highest Revenue Card"].includes(row.metricName) && row[interval] && row[interval].includes('(') ? (
                    splitNameAndAmount(row[interval])
                  ) : (
                    row[interval]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Second Table: Hodlers & PPTDH */}
      <h2 className="section-header">Hodlers & PPTDH</h2>
      <table className="metrics-table">
        <thead>
          <tr>
            <th>Metric</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {singleMetricsRows.map((row, index) => (
            <tr key={index} className={index % 2 !== 0 ? 'even-row' : ''}>
              <td className="metric-name-cell">{row.metricName}</td>
              <td>
                {Array.isArray(row.metricValue) ? (
                  <div className="pptdh-values">
                    {row.metricValue.map((item, idx) => (
                      <div key={idx}>
                        <strong>Card No: {item.ID}</strong> - {item.Name} (PPTDH: {item.PPTDH})
                      </div>
                    ))}
                  </div>
                ) : (
                  renderMetricValueWithArrow(row.metricValue)
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Contact Link */}
      <div className="contact-section">
        <p>If you would like to see any other collection metrics here, please contact us.</p>
        <Link to="/contact" className="contact-button">
          Contact
        </Link>
      </div>
    </div>
  );
};

export default InsightsComponent;
