// ContactForm.js

import React, { useState } from 'react';
import './ContactForm.css'; // Import the CSS file

const ContactForm = () => {
  // State for form fields
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // No need to handle form submission manually; Netlify will handle it
  return (
    <div className="contact-form-container">
      <h1 className="contact-header">Contact</h1>
      <p className="contact-subtext">Please complete the following fields to send a message.</p>
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        action="/thank-you" // Redirect to a thank you page after submission
      >
        {/* Hidden input for Netlify bot field */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out if you're human: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <p>
          <label>
            Your Name:
            <br />
            <input type="text" name="name" onChange={handleChange} required />
          </label>
        </p>
        <p>
          <label>
            Your Email:
            <br />
            <input type="email" name="email" onChange={handleChange} required />
          </label>
        </p>
        <p>
          <label>
            Message:
            <br />
            <textarea name="message" onChange={handleChange} required></textarea>
          </label>
        </p>
        <p>
          <button type="submit">Send Message</button>
        </p>
      </form>
      <div className="contact-footer">
        <p>Created by Vantekai</p>
        <p>Support me with a donation to <strong>6529stats.eth</strong></p>
        <p>
          <a href="https://x.com/Vantekai_" target="_blank" rel="noopener noreferrer">X: Vantekai</a>
        </p>
        <p>
          <a href="https://seize.io/Vantekai" target="_blank" rel="noopener noreferrer">Seize: Vantekai</a>
        </p>
        <p>
          <a href="mailto:admin@6529stats.xyz">admin@6529stats.xyz</a>
        </p>
      </div>
    </div>
  );
};

export default ContactForm;
