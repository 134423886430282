// Navbar.js

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink for active link styling
import { FaBars, FaTimes } from 'react-icons/fa'; // Import hamburger and close icons
import './Navbar.css'; // Import the component-specific CSS

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setActiveDropdown(null);
  };

  const toggleDropdown = (menu) => {
    if (activeDropdown === menu) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(menu);
    }
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        {/* Hamburger Menu Icon for Mobile */}
        <div className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        {/* Navigation Menu */}
        <ul className={`menu ${isMenuOpen ? 'active' : ''}`}>
          {/* Home Link */}
          <li>
            <NavLink
              to="/"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Home
            </NavLink>
          </li>

          {/* Insights Link */}
          <li>
            <NavLink
              to="/insights"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Insights
            </NavLink>
          </li>

          {/* TDH/PPTDH Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'tdh' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('tdh')}>TDH/PPTDH</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/tdh-table"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Data Table
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/tdh-stats"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Historical Stats
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Sales Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'sales' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('sales')}>Sales</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/sales-summary"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Sales Summary
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sales-analytics"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Sales Analytics
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sales-all-time"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Sales History
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Hodlers Dropdown Menu */}
          <li className={`dropdown ${activeDropdown === 'hodlers' ? 'active' : ''}`}>
            <span onClick={() => toggleDropdown('hodlers')}>Hodlers</span>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to="/hodlers/aggregated"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Aggregated
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hodlers/supply"
                  onClick={closeMenu}
                  className={({ isActive }) => (isActive ? 'active-link' : '')}
                >
                  Hodlers Supply
                </NavLink>
              </li>
              {/* You can add more Hodlers subpages here */}
            </ul>
          </li>

          {/* Prices History Link */}
          <li>
            <NavLink
              to="/prices-history"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Prices History
            </NavLink>
          </li>

          {/* My Portfolio Link */}
          <li>
            <NavLink
              to="/portfolio"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              My Portfolio
            </NavLink>
          </li>

          {/* Notifications Link */}
          <li>
            <NavLink
              to="/notifications"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Notifications
            </NavLink>
          </li>

          {/* Contact Link */}
          <li>
            <NavLink
              to="/contact"
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
