// TokenOwnersComponent.js

import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchCommunityMembersData } from './apiService';
import './TokenOwnersComponent.css';
import { CSVLink } from 'react-csv';

// Import icons (ensure these paths are correct based on your project structure)
import openseaIcon from './assets/openseaIcon.png';
import seizeIcon from './assets/seizeIcon.png';

const TokenOwnersComponent = () => {
    const { tokenid } = useParams(); // Extract tokenid from URL parameters
    const [tokenData, setTokenData] = useState(null); // Store token details
    const [holdersData, setHoldersData] = useState([]); // Store all holders
    const [loading, setLoading] = useState(true); // Loading state
    const [searchTerm, setSearchTerm] = useState(''); // Address search term
    const [profileSearchTerm, setProfileSearchTerm] = useState(''); // Profile search term
    const [showSuggestions, setShowSuggestions] = useState(false); // Toggle suggestions
    const [filteredHolders, setFilteredHolders] = useState([]); // Filtered holders based on search
    const [uniqueAddresses, setUniqueAddresses] = useState([]); // Unique addresses for suggestions

    const [lastUpdated, setLastUpdated] = useState(null); // Last updated timestamp

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchCommunityMembersData(tokenid);
                console.log('Fetched data:', data); // Debugging: Log fetched data

                const { token_id, prices, holders, lastUpdated: fetchedLastUpdated } = data;

                // Set token details
                setTokenData({
                    tokenid: token_id,
                    name: prices.Name,
                    TypeSeason: prices.TypeSeason,
                    Supply: prices.Supply,
                    Artist: prices.Artist,
                });

                // Debugging: Log each holder's data
                holders.forEach((holder, index) => {
                    console.log(`Holder ${index + 1}:`, holder);
                });

                // Sort holders by balance descending
                const sortedHolders = [...holders].sort((a, b) => b.balance - a.balance);
                setHoldersData(sortedHolders);
                setFilteredHolders(sortedHolders); // Initialize filtered data

                // Extract unique addresses for suggestions
                const addressesSet = new Set();
                holders.forEach(owner => {
                    owner.consolidation_key.split('-').forEach(address => {
                        addressesSet.add(address.toLowerCase());
                    });
                });
                setUniqueAddresses(Array.from(addressesSet));

                // Set lastUpdated from the separate field
                setLastUpdated(fetchedLastUpdated);

                setLoading(false); // Data fetched successfully
            } catch (error) {
                console.error('Error fetching community members data:', error);
                setLoading(false); // Stop loading on error
            }
        };

        // Reset state when tokenid changes to prevent data overlap
        setTokenData(null);
        setHoldersData([]);
        setFilteredHolders([]);
        setUniqueAddresses([]);
        setLastUpdated(null);
        setLoading(true);

        fetchData(); // Fetch data for the current tokenid
    }, [tokenid]);

    // Handle search by addresses and profiles
    useEffect(() => {
        if (searchTerm.trim() === '' && profileSearchTerm.trim() === '') {
            setFilteredHolders(holdersData);
        } else {
            const filtered = holdersData.filter(owner => {
                // Address search: Check if any address includes the search term
                const addressMatch = owner.consolidation_key.toLowerCase().includes(searchTerm.toLowerCase());

                // Profile search: Check if display includes the profile search term
                let profileMatch = false;
                if (profileSearchTerm.trim() !== '') {
                    if (owner.display && owner.display.toLowerCase().includes(profileSearchTerm.toLowerCase())) {
                        profileMatch = true;
                    }
                } else {
                    profileMatch = true; // If no profile search term, consider it a match
                }

                return addressMatch && profileMatch;
            });
            setFilteredHolders(filtered);
        }
    }, [searchTerm, profileSearchTerm, holdersData]);

    // Generate suggestions based on searchTerm
    const suggestions = useMemo(() => {
        if (searchTerm.length < 2) return [];
        return uniqueAddresses.filter(address =>
            address.includes(searchTerm.toLowerCase())
        ).slice(0, 5);
    }, [uniqueAddresses, searchTerm]);

    // Utility function to shorten addresses for display
    const shortenAddress = (address) => {
        if (address.length > 10) {
            return address.slice(0, 6) + '...' + address.slice(-4);
        }
        return address;
    };

    // Prepare CSV data for download
    const csvData = useMemo(() => {
        const headers = [
            { label: '#', key: 'number' },
            { label: 'Holder(s)', key: 'consolidation_key' },
            { label: 'Seize Profile', key: 'display' },
            { label: `Card ${tokenid} Owned`, key: 'balance' }, // Updated Header
            { label: 'Memes Balance', key: 'memes_balance' },
            { label: 'Unique Memes', key: 'unique_memes' },
            { label: 'Genesis Sets', key: 'genesis' },
            { label: 'Owns Nakamoto', key: 'nakamoto' },
            { label: 'Full Sets', key: 'memes_cards_sets' },
            { label: 'TDH', key: 'tdh' },
            { label: 'Level', key: 'level' },
            { label: 'Rep', key: 'rep' },
            { label: 'NIC', key: 'cic' },
            { label: 'Last Active', key: 'last_activity' },
        ];
        const data = filteredHolders.map((owner, index) => ({
            number: index + 1,
            consolidation_key: owner.consolidation_key,
            display: owner.display || 'N/A',
            balance: owner.balance !== undefined && owner.balance !== null ? owner.balance : 'N/A',
            memes_balance: owner.memes_balance !== undefined && owner.memes_balance !== null ? owner.memes_balance : 'N/A',
            unique_memes: owner.unique_memes !== undefined && owner.unique_memes !== null ? owner.unique_memes : 'N/A',
            genesis: owner.genesis !== undefined && owner.genesis !== null ? owner.genesis : 'N/A',
            nakamoto: owner.nakamoto !== undefined && owner.nakamoto !== null ? owner.nakamoto : 'N/A',
            memes_cards_sets: owner.memes_cards_sets !== undefined && owner.memes_cards_sets !== null ? owner.memes_cards_sets : 'N/A',
            tdh: owner.tdh !== undefined && owner.tdh !== null ? owner.tdh : 'N/A',
            level: owner.level !== undefined && owner.level !== null ? owner.level : 'N/A',
            rep: owner.rep !== undefined && owner.rep !== null ? owner.rep : 'N/A',
            cic: owner.cic !== undefined && owner.cic !== null ? owner.cic : 'N/A',
            last_activity: owner.last_activity ? new Date(owner.last_activity).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) : 'N/A', // Formatted Date
        }));
        return { headers, data };
    }, [filteredHolders, tokenid]);

    if (loading) {
        return <div className="spinner"></div>; // Show spinner while loading
    }

    if (!tokenData) {
        return <div>No data available for token {tokenid}.</div>; // Handle no data scenario
    }

    // Format lastUpdated to '26 Oct 2024' format
    const formattedLastUpdated = lastUpdated ? new Date(lastUpdated).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }) : 'N/A';

    return (
        <div className="owners-container">
            {/* Header Section */}
            <div className="token-header">
                <h1>
                    {`6529 Meme Card ${tokenData.tokenid}. ${tokenData.name}`}
                    {/* OpenSea Icon */}
                    <a
                        href={`https://opensea.io/assets/ethereum/0x33fd426905f149f8376e227d0c9d3340aad17af1/${tokenData.tokenid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={openseaIcon} alt="OpenSea" className="marketplace-icon" />
                    </a>
                    {/* Seize Icon */}
                    <a
                        href={`https://seize.io/the-memes/${tokenData.tokenid}?focus=live`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={seizeIcon} alt="Seize" className="marketplace-icon" />
                    </a>
                </h1>
                <p>
                    {`Token No: ${tokenData.tokenid} | Season: ${tokenData.TypeSeason} | Supply: ${tokenData.Supply} | Artist: ${tokenData.Artist} | `}
                    <Link to={`/token/${tokenid}/owners`} className="owners-link"><strong>Owners: {holdersData.length}</strong></Link>
                </p>
            </div>
            <div className="header-row">
                {/* Download CSV button */}
                <CSVLink
                    data={csvData.data}
                    headers={csvData.headers}
                    filename={`token_${tokenid}_owners.csv`}
                    className="btn"
                >
                    Download CSV
                </CSVLink>
            </div>
            {/* Details Section */}
            <p><strong>Total Holders: {holdersData.length}</strong></p>
            <p><strong>Last Updated: {formattedLastUpdated}</strong></p>
            {/* Search Boxes */}
            <div className="search-container">
                {/* Search Box for Addresses */}
                <div className="search-box">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setShowSuggestions(true);
                        }}
                        onFocus={() => setShowSuggestions(true)}
                        onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                        placeholder="Search addresses..."
                        className="search-input"
                    />
                    {showSuggestions && suggestions.length > 0 && (
                        <ul className="suggestions-list">
                            {suggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onMouseDown={() => {
                                        setSearchTerm(suggestion);
                                        setShowSuggestions(false);
                                    }}
                                >
                                    {shortenAddress(suggestion)}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                {/* Search Box for Profiles */}
                <div className="search-box">
                    <input
                        type="text"
                        value={profileSearchTerm}
                        onChange={(e) => setProfileSearchTerm(e.target.value)}
                        placeholder="Search profiles..."
                        className="search-input"
                    />
                </div>
            </div>
            {/* Owners Table */}
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Holders</th>
                        <th>Seize Profile</th>
                        <th>Card {tokenid} Owned</th> {/* Updated Header */}
                        <th>Memes Balance</th>
                        <th>Unique Memes</th>
                        <th>Genesis Sets</th>
                        <th>Owns Nakamoto</th>
                        <th>Full Sets</th>
                        <th>TDH</th>
                        <th>Level</th>
                        <th>Rep</th>
                        <th>NIC</th>
                        <th>Last Active</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredHolders.map((owner, index) => {
                        const firstAddress = owner.consolidation_key.split('-')[0].toLowerCase();
                        const cleanedFirstAddress = firstAddress.replace(/^id-/, ''); // Remove 'id-' prefix if present
                        const seizeProfileUrl = `https://seize.io/the-memes/${tokenid}?focus=live`;

                        // Debugging: Log each owner being rendered
                        console.log(`Rendering holder ${index + 1}:`, owner);

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{owner.consolidation_key}</td>
                                <td>
                                    <a href={seizeProfileUrl} target="_blank" rel="noopener noreferrer" className="profile-link">
                                        {owner.display ? owner.display : shortenAddress(cleanedFirstAddress)}
                                    </a>
                                </td>
                                <td>{owner.balance !== undefined && owner.balance !== null ? owner.balance : 'N/A'}</td> {/* Card token_id Owned */}
                                <td>{owner.memes_balance !== undefined && owner.memes_balance !== null ? owner.memes_balance : 'N/A'}</td> {/* Memes Balance */}
                                <td>{owner.unique_memes !== undefined && owner.unique_memes !== null ? owner.unique_memes : 'N/A'}</td> {/* Unique Memes */}
                                <td>{owner.genesis !== undefined && owner.genesis !== null ? owner.genesis : 'N/A'}</td> {/* Genesis Sets */}
                                <td>{owner.nakamoto !== undefined && owner.nakamoto !== null ? owner.nakamoto : 'N/A'}</td> {/* Owns Nakamoto */}
                                <td>{owner.memes_cards_sets !== undefined && owner.memes_cards_sets !== null ? owner.memes_cards_sets : 'N/A'}</td> {/* Full Sets */}
                                <td>{owner.tdh !== undefined && owner.tdh !== null ? owner.tdh : 'N/A'}</td> {/* TDH */}
                                <td>{owner.level !== undefined && owner.level !== null ? owner.level : 'N/A'}</td>
                                <td>{owner.rep !== undefined && owner.rep !== null ? owner.rep : 'N/A'}</td>
                                <td>{owner.cic !== undefined && owner.cic !== null ? owner.cic : 'N/A'}</td>
                                <td>{owner.last_activity ? new Date(owner.last_activity).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) : 'N/A'}</td> {/* Formatted Date */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

};

export default TokenOwnersComponent;
