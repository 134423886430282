// NotificationsComponent.js

import React from 'react';
import './NotificationsComponent.css'; // Import the corresponding CSS
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function NotificationsComponent() {
    return (
        <div className="notifications-container">
            <h1>Email Notifications</h1>
            <p>
                On this page, I will demonstrate some of the options available for email notifications. 
                Essentially, I can set up notifications around pretty much any statistic or attribute 
                that is available within the website. Notifications can also be sent at custom frequencies 
                ranging from every few minutes to daily, weekly, monthly, etc.
            </p>
            <p>
                These notifications are ultimately going to be a paid-for service, but initially, I will make 
                some available for 5 users for a month or so to ensure the setting up and emailing service 
                works efficiently. 
            </p>

            {/* Added Link component for the button */}
            <Link to="/contact" className="green-button">
                Contact To Join This Free Trial
            </Link>

            <h2>Example 1 - Portfolio Update</h2>
            <p>
                The columns are self-explanatory and it also comes with a timestamp. Currently, the data only 
                updates hourly, but this will change. This can be emailed at any desired frequency and 
                will appear in this format:
            </p>

            <div className="image-item">
                <a href="/images/notifications/MyPortfolio.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/MyPortfolio.png" 
                        alt="Portfolio Update Example" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 2 - Portfolio - Cards NOT Owned</h2>
            <p>
                This will email all the cards that you don't currently have in your Portfolio along with useful stats. It
            </p>

            <div className="image-item">
                <a href="/images/notifications/NotOwned.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/NotOwned.png" 
                        alt="Cards Not Owned Example" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 3 - Portfolio - Cards NOT Owned that ALSO have PPTDH Under 0.003</h2>

            <p>
                Following on from the previous example this can be extended further with additional filters such as cards not owned that <strong>ALSO</strong> have PPTDH under 0.003
            </p>

            <p>
                This will email all the cards that you don't currently have in your Portfolio that ALSO have PPTDH under 0.003. It will provide you with the Highest Bid Floor Price and Last Sale Price so you can act accordingly!
            </p>

            <div className="image-item">
                <a href="/images/notifications/NotOwnedPPTDHisUnder003.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/NotOwnedPPTDHisUnder003.png" 
                        alt="Cards Not Owned with PPTDH Under .003 Example" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>
            <br /><br />
            <p>
                What makes it interesting is this could be applied to any criteria not just cards owned/not owned and PPTDH.
            </p>
            <p>
            <strong>Examples</strong>
            </p>

            <div className="notification-list">
                <p><strong>1:</strong> Alert me to any cards I don't own under 0.06529</p>
                <p><strong>2:</strong> Alert me to card no 4 with bids over 5.00</p>
                <p><strong>3:</strong> Alert me to any card I own with bids over 1.00</p>
                <p><strong>4:</strong> Alert me to any card in SZN9 with ask under 0.1</p>
                <p><strong>5:</strong> Alert me to any card with PPTDH under 0.002</p>
                <p><strong>6:</strong> Alert me to all cards with PPTDH under 0.003</p>
                <p><strong>7:</strong> Alert me to all cards with bids over 1.00</p>
                <p><strong>8:</strong> Alert me to all cards with asks under 0.03</p>
                <p><strong>9:</strong> Combinations of any of the above so you get the gist!</p>
            </div>

            <p>
                The monitoring and notifying can be set up to any timescale from minutes to daily, etc.
            </p>

            <p>
                <strong>This will ultimately be a paid for service but intially 5 spaces are available for beta testers!</strong>
            </p>

                        {/* Added Link component for the button */}
                        <Link to="/contact" className="green-button">
                Contact To Join This Free Trial
            </Link>

            <h2>Example 4 - Bid & Ask Prices Any Cards</h2>
            <p>
                This monitors the bid and ask prices for any token you maybe interested in bidding on amongst other things.  
            </p>   
            <p> 
                It will monitor bids constantly and compare them against any bids you may have and notify you within
                the email if you are the current highest bidder or not (see example below with the 
                red text next to Token 156 (MEMOVEMENT))
            </p>

            <div className="image-item">
                <a href="/images/notifications/BidAsk.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/BidAsk.png" 
                        alt="Email deomstrating bid and ask price monitoring" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <p> 
                Thresholds can also be set for the bids. ie  Only receive an email if the bid for card no 4 is over 1 ETH for example. This could save receiving very long emails if youre monitoring lots of cards.

            </p>
            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 5 - Sales Updates and Notifications</h2>
            <p>
                Sales updates and summaries can be customised to be sent at any interval requried from every hour up to daily, weekly or monthly summaries.
            </p>
            <p>
                It is also possible to limit the notifications to only selected cards or SZNs etc etc 
            </p>
                        {/* Added line breaks for spacing */}
                        <br /><br />

            <div className="image-item">
                <a href="/images/notifications/salesreport1hr.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/salesreport1hr.png" 
                        alt="Sales Report for 6529 Memes" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 6 - Trading Alert - Asks To Meet To Double Floor</h2>
            <p>
                Here we identify how many ask prices need to me met and at what cost for the floor price to increase by 100% or more
            </p>
            <p>
                The results for all cards are saved to a csv file and the cards with 3 ort less asks requried are emailed to me
            </p>
            <p>
                The ask prices are updated every 1-2 minutes and the notifications can be set to any desired time interval 
            </p>
                        {/* Added line breaks for spacing */}
                        <br /><br />

            <div className="image-item">
                <a href="/images/notifications/AsksToDoubleFloor.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/AsksToDoubleFloor.png" 
                        alt="Report on how many asks to double the floor price per card" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 7 - Wallet Tracker</h2>
            <p>
            Another email notification I have created for myself which is great for keeping in touch with all those wallets from 2021-2022 which are full of god knows what NFTS.
            </p>
            <p>
            Instead of having to remember to check them periodically I have now set a notification to email me summaries including a csv file.
            </p>
            <p>
            These an be sent daily, weekly, monthly or even only when triggered - eg If any NFT has a top bid of over 0.1 for example.
            </p>

            <p>
            With this wallet it identifies the highest bids per collection in the wallet and displays the collections in that order. Sorting order can be customised easily depending on the contents of the wallet.
            </p>

            {/* Replacing the image with a video */}
            <div className="video-item">
                <video 
                    src="/images/notifications/WalletTrackerVideo.mp4" 
                    controls 
                    loading="lazy" 
                    width="600" 
                    poster="/images/notifications/WalletReport.png" // Optional: poster image before the video loads
                >
                    Your browser does not support the video tag.
                </video>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

        </div>
    );
}

export default NotificationsComponent;
